<template>
    <div class="page">
        <div class="book-detail-container">
            <div class="book-cover">
                <router-link :to="{path: '/detail/'+book.id+'.html'}">
                    <img v-bind:src="'http://img.books.leizhenxd.com/books/source/'+book.sourceBookId+'/cover.jpg'" :onerror="defaultImg" width="100%">
                </router-link>
            </div> 
            <div class="book-detail">
                <div class="book-name">{{book.bookName}}</div>
                <div class="book-tag">作者: <span class="orange">{{book.author}}</span></div>
                <!-- <div class="book-desc grey">{{book.description}}</div> -->
                <div class="book-tag">
                    <span class="grey">{{book.lastUpdateChapterTime}} | </span>
                    {{book.cat}} |
                    <span class="orange"> {{book.status}} </span>
                </div>
                <div class="book-tag grey">
                    字数：{{formatterNumber(book.wordsNumber)}}万字 | 热度：{{book.hotPoint}}
                </div>
                <div class="book-tag"><span class="grey">最新:</span>{{book.lastUpdateChapterName}}</div>
            </div>
        </div>
        <div class="book-desc grey">
            {{book.description}}
        </div>
        <InFeedAdsense
            data-ad-layout-key="-cm+8l-1m-2u+bi"
            data-ad-client="ca-pub-2093034756983913"
            data-ad-slot="8430764648"
            >
        </InFeedAdsense>
        <ul class="chapters">
            <li class="profile">共{{book.totalChapter}}章</li>
            <li v-for="chapter in book.chapters" v-bind:key="chapter.id">
                <a :href="'/read/'+book.id+'/'+chapter.sourceChapterId+'.html'">{{chapter.chapterName}}</a>
            </li>
        </ul>
    </div>
</template>
<script>
import {bookDetail} from '@/apis/api'
export default {
    name: 'BookDetail',
    data(){
        return {
            book: {},
            defaultImg: 'this.src="'+require('@/assets/nocover.jpg')+'"',
        }
    },
    computed:{
        formatterNumber(){
            return function(wordsNumber){
                return (wordsNumber/10000).toFixed(2);
            }
        }
    },
    mounted(){
        bookDetail(this.$route.params.id).then((data) => {
            this.book = data.data.data;
            if(this.book) {
                document.title = this.book.bookName;
            }
        })
        .catch(() => {
            
        })
    }
}
</script>
<style lang="less" scoped>
    .page{background-color: #ccc;}
    .book-detail-container{
        background-color: white;
        display: flex;
        flex-direction: row;
        margin: 5px 0px;
    }
    .book-cover{
        width: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
        a{
            img{
                box-shadow: 0 0 5px #111;
            }
        }
    }
    
    .book-desc {
        background-color: white;
        margin: 10px 0px;
        padding: 5px;
    }
    .chapters{
        list-style: none;
        padding-left: 15px;
        margin:0;
        background-color: white;
        .profile{
            margin: 10px 0px;
            font-size: 1.1rem;
        }
        li{
            margin: 7px 0px;
            a{
                text-decoration: none;
                color: cadetblue;
            }
        }
        
    }
</style>